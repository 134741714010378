import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Steps.css";
import Aos from "aos";
import { Helmet } from "react-helmet";

const OnboardingFaq = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("ios");
  const [activeFrist, setActiveFrist] = useState("active-first-tab");
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 2000, // duration of the animations in ms
    });
  }, []);

  return (
    <div>
      <Helmet>
  <title>AremkoPay - Onboarding FAQ</title>
  <meta name="description" content="Find answers to frequently asked questions about getting started with AremkoPay. Learn how to set up your account and start transferring money securely." />
  <meta name="keywords" content="AremkoPay onboarding FAQ, money transfer FAQ, account setup FAQ, AremkoPay support, getting started FAQ" />
  <meta property="og:title" content="AremkoPay - Onboarding FAQ" />
  <meta property="og:description" content="Find answers to frequently asked questions about getting started with AremkoPay. Learn how to set up your account and start transferring money securely." />
  <meta property="og:url" content="https://www.aremkopay.com/onboarding-faq" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/onboarding-faq" />
</Helmet>

      <div className="page_wrapper container">
        {/* FAQ Tab Section Start */}
        <section className="about_us_section">
          <div
            className="container aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={3000}
            bis_skin_checked={1}
          >
            <div
              className="section_title aos-init aos-animate"
              bis_skin_checked={1}
            >
              <span className="title_badge">Help</span>
              <h2>
                Onboarding
                <br /> <span> FAQs</span>{" "}
              </h2>
             
            </div>
          </div>
        </section>

        <section className="gap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="accordion two">
                  <div
                    className={`accordion-item ${
                      activeIndex === 0 ? "active" : ""
                    }`}
                  >
                    <NavLink
                      to="javascript:void(0)"
                      className="heading "
                     
                      onClick={() => toggleAccordion(0)}
                    >
                      <div className="icon" />
                      <div className="title">
                        {" "}
                        I'm getting a black blank screen. What should I do?
                      </div>
                    </NavLink>
                    <div
                      className="content"
                      ref={(el) => (contentRefs.current[0] = el)}
                      style={{
                        maxHeight:
                          activeIndex === 0
                            ? contentRefs.current[0]?.scrollHeight
                            : 0,
                        overflow: "hidden",
                        transition: "max-height 300ms ease"
                      }}
                    >
                      <div className="text">
                        <p>Does your screen look something like this?</p>
                        <div className="row">
                          <div className="col-6 col-md-5 col-lg-2">
                            <div className="cardcss">
                              <img
                                className="card-img-top"
                                src="assets/images/photo/blankscreen1.png"
                                alt="I'm getting a black blank screen. What should I do?"
                              />
                            </div>
                          </div>
                          <div className="col-6 col-md-5 col-lg-2">
                            <div className="cardcss">
                              <img
                                className="card-img-top"
                                src="assets/images/photo/blankscreen2.png"
                                alt="I'm getting a black blank screen. What should I do?"
                              />
                            </div>
                          </div>
                        </div>
                        <p>
                          This means the CAMERA access to your default BROWSER
                          is denied. Please follow the steps below to Allow
                          camera access.
                        </p>
                        <ul
                          className="nav nav-pills nav-justified flex-column flex-sm-row style1"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link ${activeTab === "ios" ? "active" : ""}`}
                              id="ios-tab"
                              onClick={() => setActiveTab("ios")}
                              type="button"
                            >
                              iOS
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link ${activeTab === "android" ? "active" : ""}`}
                              id="android-tab"
                              onClick={() => setActiveTab("android")}
                              type="button"
                            >
                              Android
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className={`tab-pane fade ${activeTab === "ios" ? "show active" : ""}`}
                          >
                            <div className="row align-items-center">
                              <div className="col-md-12 mt-1 mt-sm-0 pt-0 pt-sm-0">
                                <div className="full mb-1">
                                  <div
                                    className="row wow fadeInUp animated animated animated"
                                    data-wow-delay="0.4s"
                                    style={{
                                      visibility: "visible",
                                      animationDelay: "0.4s",
                                      animationName: "fadeInUp",
                                    }}
                                  >
                                    <div className="col-12 col-md-12 col-xs-12 offset-xs-1">
                                      <p>i. Go to Settings → Safari</p>
                                      <div className="row">
                                        <div className="col-12 col-md-5 col-lg-3">
                                          <div className="cardcss">
                                            <img
                                              className="card-img-top"
                                              src="assets/images/photo/apple1.png"
                                              alt="Step1"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <p>
                                        ii. Scroll to find "Camera" under
                                        SETTINGS FOR WEBSITES section
                                      </p>
                                      <div className="row">
                                        <div className="col-12 col-md-5 col-lg-3">
                                          <div className="cardcss">
                                            <img
                                              className="card-img-top"
                                              src="assets/images/photo/apple2.png"
                                              alt="Step2"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <p>
                                        iii. Tap on Camera option and you will
                                        be navigated to CAMERA ACCESS ON ALL
                                        WEBSITES. Tap on "Allow" option.
                                      </p>
                                      <div className="row">
                                        <div className="col-12 col-md-5 col-lg-3">
                                          <div className="cardcss">
                                            <img
                                              className="card-img-top"
                                              src="assets/images/photo/apple3.png"
                                              alt="Step1"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*end col*/}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end tab pane*/}
                          <div
                            className={`tab-pane fade ${activeTab === "android" ? "show active" : ""}`}
                          >
                            <div className="row align-items-center">
                              <div className="col-md-12 mt-1 mt-sm-0 pt-0 pt-sm-0">
                                <p>i. Go to Settings and tap on Apps</p>
                                <div className="row">
                                  <div className="col-12 col-md-5 col-lg-3">
                                    <div className="cardcss">
                                      <img
                                        className="card-img-top"
                                        src="assets/images/photo/ad1.png"
                                        alt="Step1"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  ii. Scroll to find your default browser app.
                                  Mostly it is Google Chrome for Android device,
                                  unless explicitly changed.
                                </p>
                                <div className="row">
                                  <div className="col-12 col-md-5 col-lg-3">
                                    <div className="cardcss">
                                      <img
                                        className="card-img-top"
                                        src="assets/images/photo/ad2.png"
                                        alt="Step2"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  iii. Chrome → Permissions will show you what
                                  is enabled. Camera permission need to be
                                  enabled here.{" "}
                                </p>
                                <div className="row">
                                  <div className="col-12 col-md-5 col-lg-3">
                                    <div className="cardcss">
                                      <img
                                        className="card-img-top"
                                        src="assets/images/photo/ad3.png"
                                        alt="Step3"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  iv. App permissions will show what is allowed
                                  and what's denied.
                                </p>
                                <div className="row">
                                  <div className="col-12 col-md-5 col-lg-3">
                                    <div className="cardcss">
                                      <img
                                        className="card-img-top"
                                        src="assets/images/photo/ad4.png"
                                        alt="Step4"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  v. Tap on Camera to Allow camera permission to
                                  Chrome.
                                </p>
                                <div className="row">
                                  <div className="col-12 col-md-5 col-lg-3">
                                    <div className="cardcss">
                                      <img
                                        className="card-img-top"
                                        src="assets/images/photo/ad5.png"
                                        alt="Step5"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*end col*/}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end tab pane*/}
                        </div>
                        {/*end tab content*/}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`accordion-item  ${
                      activeIndex === 1 ? "active" : ""
                    }`}
                  >
                    <NavLink
                      to="javascript:void(0)"
                      className="heading"
                      onClick={() => toggleAccordion(1)}
                    >
                      <div className="icon" />
                      <div className="title">
                        I'm getting error message saying, 'Wrong ID document or
                        something went wrong...
                      </div>
                    </NavLink>
                    <div
                      className="content"
                      ref={(el) => (contentRefs.current[1] = el)}
                      style={{
                        maxHeight:
                          activeIndex === 1
                            ? contentRefs.current[1]?.scrollHeight
                            : 0,
                        overflow: "hidden",
                        transition: "max-height 300ms ease",
                      }}
                    >
                      <div className="text">
                        <p>Does your screen look something like this?</p>
                        <div className="row">
                          <div className="col-6 col-md-5 col-lg-3">
                            <div className="cardcss">
                              <img
                                className="card-img-top"
                                src="assets/images/photo/ID Upload failure.png"
                                alt="Wrong ID document or something went wrong."
                              />
                            </div>
                          </div>
                        </div>
                        <p className="mt-2">
                          This means the ID you uploaded is not supported or was
                          having glare or blur while uploading. This may have
                          resulted us in not been able to capture ID details.
                          Please give it another try or contact our support
                          team.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Tab Section End */}
      </div>
    </div>
  );
};

export default OnboardingFaq;
