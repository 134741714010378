import { Route, Routes, useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import Home from "./Home/Home";
import "./App.css";
import Footer from "./Components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandConditions from "./pages/TermsandConditions";
import ComplaintPolicy from "./pages/ComplaintPolicy";
import { useLayoutEffect } from "react";
import Header from "./Components/Header";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Help from "./Help/Help";
import Onboarding from "./Help/Onboarding";
import Livenesscheck from "./Help/Livenesscheck";
import Finish from "./Help/Finish";
import OnboardingFaq from "./Help/OnboardingFaq";
import DataSafety from "./Help/DataSafety";
import Generalfaq from "./Help/Generalfaq";

function App() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<Help />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/livenesscheck" element={<Livenesscheck />} />
        <Route path="/finish" element={<Finish />} />
        <Route path="/onboarding-faq" element={<OnboardingFaq />} />
        <Route path="/data-safety" element={<DataSafety />} />
        <Route path="/faq" element={<Generalfaq />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsandConditions />} />
        <Route path="/complaint-policy" element={<ComplaintPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
