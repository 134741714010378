import Aos from "aos";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000, // duration of the animations in ms
    });
  }, []);
  return (
    <>
    <Helmet>
  <title>AremkoPay - About Us</title>
  <meta name="description" content="Learn more about AremkoPay, our mission, vision, and the team dedicated to providing secure and fast money transfer services." />
  <meta name="keywords" content="about AremkoPay, money transfer service, secure money transfer, AremkoPay team, AremkoPay mission" />
  <meta property="og:title" content="AremkoPay - About Us" />
  <meta property="og:description" content="Learn more about AremkoPay, our mission, vision, and the team dedicated to providing secure and fast money transfer services." />
  <meta property="og:url" content="https://www.aremkopay.com/about-us" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/about-us" />
</Helmet>

      <div className="page_wrapper">
        {/* About Us Section Start */}
        <section className="about_us_section">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="section_title aos-init aos-animate">
              <span className="title_badge">About us</span>
              <h2>
                Aremko
                <br /> <span> Seamless and Secure Money Transfers</span>{" "}
              </h2>
              <p>
              At Aremko Pay, we understand the importance of reliable and efficient financial services, whether you're supporting family abroad, <br /> paying for international education, or conducting other transactions.
              </p>
            </div>
          </div>
        </section>

        <section className="row_am service_section about_service">
          <div className="container">
            <div className="row service_blocks">
              <div className="col-md-6">
                <div
                  className="service_text aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                 
                  <h2>
                    <span>Our </span> Mission
                  </h2>
                  <p>
                  Our mission is to simplify and streamline the process of sending money across borders. We are committed to providing a secure, fast, and cost-effective solution for your international money transfer needs. With Aremko Pay, you can rest assured that your funds will reach their destination safely and on time.
                  </p>
            
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="inner_block rotate_left aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="star_img">
                    <img src="images/bigstar_dark.png" alt srcSet />
                    <img src="images/smallStar_dark.png" alt srcSet />
                  </div>
                  <div className="img video_player">
                    <img src="images/about_service_1.png" alt />
                    <a
                      href="#"
                      className="popup-youtube play-button play_icon"
                      data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                      data-toggle="modal"
                      data-target="#myModal"
                      title="XJj2PbenIsU"
                    >
                      <img src="images/play_white.png" alt="img" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row service_blocks flex-row-reverse">
              <div className="col-md-6">
                <div
                  className="service_text right_side aos-init"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
               
                  <h2>
                    <span>Why Choose </span> Aremko Pay?
                  </h2>
              
                  <ul className="feature_list">
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled" />
                        </span>
                      </div>
                      <div className="text">
                        <p><strong>Reliability:</strong> We pride ourselves on our robust network and advanced technology, ensuring your transfers are processed without delays.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled" />
                        </span>
                      </div>
                      <div className="text">
                        <p><strong>Security:</strong> Your transactions are protected with the highest standards of security and encryption, giving you peace of mind.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled" />
                        </span>
                      </div>
                      <div className="text">
                        <p><strong>Competitive Rates:</strong> We offer competitive exchange rates and low fees, ensuring you get the most value for your money.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled" />
                        </span>
                      </div>
                      <div className="text">
                        <p><strong>Customer Support:</strong> Our dedicated customer support team is here to assist you every step of the way, providing customer service and prompt responses to your inquiries.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="inner_block rotate_right aos-init"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="star_img">
                    <img src="images/bigstar_dark.png" alt srcSet />
                    <img src="images/smallStar_dark.png" alt srcSet />
                  </div>
                  <div className="img">
                    <img src="images/about_service_2.png" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
