import Aos from 'aos';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom'

const Finish = () => {
    useEffect(() => {
        Aos.init({
          duration: 2000, // duration of the animations in ms
        });
      }, []);
  return (
    <div>
      <Helmet>
  <title>AremkoPay - Finish</title>
  <meta name="description" content="Complete your setup with AremkoPay. Finalize your account setup and get ready to transfer money securely and efficiently." />
  <meta name="keywords" content="AremkoPay finish, complete setup, finalize account, money transfer, AremkoPay setup" />
  <meta property="og:title" content="AremkoPay - Finish" />
  <meta property="og:description" content="Complete your setup with AremkoPay. Finalize your account setup and get ready to transfer money securely and efficiently." />
  <meta property="og:url" content="https://www.aremkopay.com/finish" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/finish" />
</Helmet>

         <div className="page_wrapper container">
        {/* FAQ Tab Section Start */}
        <section className="about_us_section">
          <div
            className="container aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={3000}
            bis_skin_checked={1}
          >
            <div
              className="section_title aos-init aos-animate"
              bis_skin_checked={1}
            >
              <span className="title_badge">Help</span>
              <h2>
              KYC 
                <br /> <span> Documents  Upload</span>{" "}
              </h2>
              <p>
              On our mobile app to complete your onboarding process we require your Photo ID and a selfie. <br />We use ID Scan solution which takes less than a minute to complete this for you
              </p>
            </div>
          </div>
        </section>
        <section className="gap meet-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                    <div className="card px-0 pb-0 mb-3">
                                 
                                        {/*<div class="row justify-content-center">
                                          <div class="col-4">

                                              <button type="button" class="btn btn-primary video-btn2" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/qceO0YPfcVo" data-bs-target="#myModal">
                                                  <i class="fa fa-play"></i> Watch Explainer Video
                                              </button>
                                          </div>
                                      </div>*/}
                                       <div id="msform">
                                                {/* progressbar */}
                                                <ul id="progressbar">
                                                    <NavLink to="/onboarding">
                                                        <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                                    <NavLink to="/livenesscheck">
                                                        <li className="active" id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                                    <NavLink to="/finish">
                                                        <li className="active" id="confirm"><strong>Finish</strong></li></NavLink>
                                                </ul>
                                                {/* fieldsets */}
                                                <fieldset>
                                                    <div className="form-card">
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss">
                                                                    <img className="card-img-top" src="assets/images/photo/finishimg.png" alt="Finish Image" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">On completion of your IDScan and Liveness check you will get a success screen to confirm completion of onboarding.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 text-center">
                                                                <h5 className="purple-text text-center mt-3">If you have any further questions then please visit our Onboarding FAQs page.</h5>
                                                            </div>
                                                            <div className='col-xl-6 col-lg-6 col-12'>
                                                            <NavLink to="/onboarding-faq" className="btn mt-3">FAQs</NavLink>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
   
        {/* FAQ Tab Section End */}
      </div>
    </div>
  )
}

export default Finish
