import React, { useEffect } from "react";
import Aos from "aos";
import { NavLink } from "react-router-dom";
import "./Steps.css";
import { Helmet } from "react-helmet";

const DataSafety = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000, // duration of the animations in ms
    });
  }, []);
  return (
    <div>
      <Helmet>
  <title>AremkoPay - Data Safety</title>
  <meta name="description" content="Learn how AremkoPay ensures the safety and security of your data. Explore our data protection measures and privacy practices." />
  <meta name="keywords" content="AremkoPay data safety, data security, data protection, privacy practices, AremkoPay security" />
  <meta property="og:title" content="AremkoPay - Data Safety" />
  <meta property="og:description" content="Learn how AremkoPay ensures the safety and security of your data. Explore our data protection measures and privacy practices." />
  <meta property="og:url" content="https://www.aremkopay.com/data-safety" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/data-safety" />
</Helmet>

      <div className="page_wrapper container">
        <section
          className="about_us_section"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <div className="container aos-init aos-animate">
            <div
              className="section_title aos-init aos-animate"
              bis_skin_checked={1}
            >
              <span className="title_badge">Data Safety</span>
              <h2>
                Data Safety
                <br /> <span> </span>{" "}
              </h2>
              <p>
                If you have completed your registration with SendTango but
                haven’t initiated any transaction, then you can send us a
                request to delete your account with us. Deleting your account
                will remove your data we have stored with us.
              </p>
            </div>
          </div>
        </section>
        <section className="gap">
          <div className="container" bis_skin_checked={1}>
           
            <div
              className="row text-center justify-content-center"
              bis_skin_checked={1}
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div>
              <h3 className="mb-2">Temporary Account Deletion</h3>
              <p className="mb-5">
                Please follow the below steps to delete your account on a
                temporary basis.
              </p>
              </div>
            </div>
            <div className="row" bis_skin_checked={1}  data-aos="fade-up"
          data-aos-duration={1500}>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/1.png"
                    alt="Data Safety"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text">
                      <strong>Step 1:</strong> Go to the dashboard and open the
                      ‘Settings’ tab. Click on the ‘Delete My Account’ option.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/2.png"
                    alt="Data Safety"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text mb-4">
                      <strong>Step 2:</strong> Click the ‘Yes’ option to
                      temporarily delete your account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/3.png"
                    alt="Data Safety"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text">
                      <strong>Step 3:</strong> To proceed with your request,
                      please click on the ‘Yes’ option. We will delete all your
                      data records stored in our system. You can cancel the
                      request by clicking on the ‘No’ option.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/4.png"
                    alt="Data Safety"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text mb-4">
                      <strong>Step 4:</strong> Your request for account deletion
                      will be submitted. Our team will reach out to you shortly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              className="row text-center justify-content-center"
              bis_skin_checked={1}
            >
             <div  data-aos="fade-up"
          data-aos-duration={1500}>
             <h3 className="mb-2 mt-3">Permanent Account Deletion</h3>
              <p className="mb-5">
                Please follow the below steps to delete your account
                permanently.
              </p>
             </div>
            </div>
            <div className="row justify-content-center"  bis_skin_checked={1}>
              <div className="col-12 col-md-6 col-lg-6"  data-aos="fade-up"
          data-aos-duration={1500} bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/1.png"
                    alt="Data Safety?"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text">
                      <strong>Step 1:</strong> Go to the dashboard and open the
                      ‘Settings’ tab. Click on the ‘Delete My Account’ option.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6"  data-aos="fade-up"
          data-aos-duration={1500} bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/2.png"
                    alt="Data Safety?"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text">
                      <strong>Step 2:</strong> Click on the ‘No. Delete my
                      account permanently’ option to delete your account
                      permanently.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/5.png"
                    alt="Data Safety?"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text">
                      <strong>Step 3:</strong> To proceed with your request,
                      please click on the ‘Yes’ option. We will delete all your
                      data records stored in our system.&nbsp;You can cancel the
                      request by clicking on the ‘No’ option.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked={1}>
                <div
                  className="card cardcss"
                  bis_skin_checked={1}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.11) 0px 5px 40px 0px",
                    margin: "0px 0px 20px",
                    borderRadius: 35,
                  }}
                >
                  <img
                    className="card-img-top"
                    src="img/data-safety/4.png"
                    alt="Data Safety?"
                  />
                  <div className="card-body" bis_skin_checked={1}>
                    <p className="card-text mb-4">
                      <strong>Step 4:</strong> Your request for account deletion
                      will be submitted. Our team will reach out to you shortly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DataSafety;
