import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import "./Steps.css";
import Aos from 'aos';
import { Helmet } from 'react-helmet';
const Onboarding = () => {
    useEffect(() => {
        Aos.init({
          duration: 2000, // duration of the animations in ms
        });
      }, []);
  return (
    <div>
        <Helmet>
  <title>AremkoPay - Onboarding</title>
  <meta name="description" content="Get started with AremkoPay. Learn how to set up your account and begin transferring money quickly and securely." />
  <meta name="keywords" content="AremkoPay onboarding, money transfer setup, account setup, AremkoPay guide, getting started with AremkoPay" />
  <meta property="og:title" content="AremkoPay - Onboarding" />
  <meta property="og:description" content="Get started with AremkoPay. Learn how to set up your account and begin transferring money quickly and securely." />
  <meta property="og:url" content="https://www.aremkopay.com/onboarding" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/onboarding" />
</Helmet>

        <div className="page_wrapper container">
        {/* FAQ Tab Section Start */}
        <section className="about_us_section">
          <div
            className="container aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={3000}
            bis_skin_checked={1}
          >
            <div
              className="section_title aos-init aos-animate"
              bis_skin_checked={1}
            >
              <span className="title_badge">Help</span>
              <h2>
              KYC 
                <br /> <span> Documents  Upload</span>{" "}
              </h2>
              <p>
              On our mobile app to complete your onboarding process we require your Photo ID and a selfie. <br />We use ID Scan solution which takes less than a minute to complete this for you
              </p>
            </div>
          </div>
        </section>
        <section className="gap meet-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                    <div className="card px-0 pb-0 mb-3">
                                                                           
                                        {/*<div class="row justify-content-center">
                                          <div class="col-4">

                                              <button type="button" class="btn btn-primary video-btn2" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/qceO0YPfcVo" data-bs-target="#myModal">
                                                  <i class="fa fa-play"></i> Watch Explainer Video
                                              </button>
                                          </div>
                                      </div>*/}
                                        <div id="msform">
                                            {/* progressbar */}
                                            <ul id="progressbar">
                                                <NavLink to="/onboarding">
                                                    <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                                <NavLink to="/livenesscheck">
                                                    <li id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                                <NavLink to="/finish">
                                                    <li id="confirm"><strong>Finish</strong></li></NavLink>
                                            </ul>
                                            {/* fieldsets */}
                                            <fieldset>
                                                <div className="form-card">
                                                    <div className="row justify-content-center">
                                                        <h4 className="mb-4">How To Scan Id?</h4>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/scanid1.png" alt="How to scan Id?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Try not to take the photo from any other angle. It will work regardless, but parallel is better.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/scanid2.png" alt="How to scan Id?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Try not to take the photo from any other angle. It will work regardless, but parallel is better.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="hrcss" />
                                                    <div className="row justify-content-center">
                                                        <h4 className="mb-4 mt-2">What to avoid?</h4>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/scanid4.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Please avoid flash or glares.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/scanid6.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Please avoid shadows over the ID.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/scanid7.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Please remove any background clutter.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/scanid3.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text mb-4">Please make sure your details aren't blurry.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/scanid5.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Please make sure you are not cropping or skewing the ID.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              <br />
                                                <NavLink className="btn" to="/livenesscheck">Next</NavLink>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
   
        {/* FAQ Tab Section End */}
      </div>
    </div>
  )
}

export default Onboarding
