import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import "./Steps.css";
import Aos from 'aos';
import { Helmet } from 'react-helmet';
const Livenesscheck = () => {
    useEffect(() => {
        Aos.init({
          duration: 2000, // duration of the animations in ms
        });
      }, []);
  return (

    <div>
        <Helmet>
  <title>AremkoPay - Liveness Check</title>
  <meta name="description" content="Ensure your AremkoPay account is active and secure with our liveness check. Verify your identity quickly and easily." />
  <meta name="keywords" content="AremkoPay liveness check, account verification, identity verification, secure money transfer, AremkoPay security" />
  <meta property="og:title" content="AremkoPay - Liveness Check" />
  <meta property="og:description" content="Ensure your AremkoPay account is active and secure with our liveness check. Verify your identity quickly and easily." />
  <meta property="og:url" content="https://www.aremkopay.com/livenesscheck" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/livenesscheck" />
</Helmet>

         <div className="page_wrapper container">
        {/* FAQ Tab Section Start */}
        <section className="about_us_section">
          <div
            className="container aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={3000}
            bis_skin_checked={1}
          >
            <div
              className="section_title aos-init aos-animate"
              bis_skin_checked={1}
            >
              <span className="title_badge">Help</span>
              <h2>
              KYC 
                <br /> <span> Documents  Upload</span>{" "}
              </h2>
              <p>
              On our mobile app to complete your onboarding process we require your Photo ID and a selfie. <br />We use ID Scan solution which takes less than a minute to complete this for you
              </p>
            </div>
          </div>
        </section>
        <section className="gap meet-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                    <div className="card px-0 pb-0 mb-3">
                                        
                                        {/*<div class="row justify-content-center">
                                          <div class="col-4">

                                              <button type="button" class="btn btn-primary video-btn2" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/qceO0YPfcVo" data-bs-target="#myModal">
                                                  <i class="fa fa-play"></i> Watch Explainer Video
                                              </button>
                                          </div>
                                      </div>*/}
                                        <div id="msform">
                                            {/* progressbar */}
                                            <ul id="progressbar">
                                                <NavLink to="/onboarding">
                                                    <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                                <NavLink to="/livenesscheck">
                                                    <li className="active" id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                                <NavLink to="/finish">
                                                    <li id="confirm"><strong>Finish</strong></li></NavLink>
                                            </ul>
                                            {/* fieldsets */}
                                            <fieldset>
                                                <div className="form-card">
                                                    <div className="row justify-content-center">
                                                        <h4 className="mb-4">Taking Perfect Photo</h4>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto1.png" alt="Taking Perfect Photo" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Make sure the camera has a consistent aspect ratio, so it does not stretch the image in any direction.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="hrcss" />
                                                    <div className="row justify-content-center">
                                                        <h4 className="mb-4 mt-2">What to avoid?</h4>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto7.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text ">Avoid shadows on the face or a strong side light.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto3.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Make sure your face is within the circle guidelines shown on the screen.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto4.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Make sure you are in a well-lit room that is not too dark.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto2.png" alt="What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Turn off any filters that might distort the image but keep the white light correction ON.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto6.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Avoid having a light source behind the person's head, such as from a window.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto8.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">Avoid anything covering the face, such as masks, hats, sunglasses or hair flopping over the face.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                                <img className="card-img-top" src="assets/images/photo/takephoto5.png" alt=" What to avoid?" />
                                                                <div className="card-body">
                                                                    <p className="card-text">For active liveness only - When performing actions such as turn right, do not over-rotate the face to 90 degrees, an angle of 35 degrees should be enough. Start with the camera raised so there is space to look down.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <NavLink to="/onboarding" type="button" className="previous action-button-previous">Previous</NavLink>
                                                <NavLink to="/finish" type="button" className="btn">Next</NavLink>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
   
        {/* FAQ Tab Section End */}
      </div>
    </div>
  )
}

export default Livenesscheck
