import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [showCookiesConsent, setShowCookiesConsent] = useState(true);
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setShowCookiesConsent(false);
    }
}, [])
const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowCookiesConsent(false);
  };
  return (
    <div>
      <header style={{ background: "#000" }}>
        {/* container start */}
        <div className="container">
          {/* navigation bar */}
          <nav className="navbar navbar-expand-lg">
            <NavLink className="navbar-brand" to="/">
              <img
                src="images/logo.svg"
                style={{ width: "200px" }}
                alt="Logo"
              />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                {/* <i class="icofont-navigation-menu ico_menu"></i> */}
                <span className="toggle-wrap">
                  <span className="toggle-bar" />
                </span>
              </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                {/* secondery menu start */}
                <li className="nav-item has_dropdown">
                  <NavLink className="nav-link" to="/">
                    Money Transfer
                  </NavLink>
                </li>
                {/* secondery menu end */}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/help">
                    Help
                  </NavLink>
                </li>
                {/* secondery menu start */}

                {/* secondery menu end */}

                <li className="nav-item">
                  <a className="nav-link" href="https://aremkopay.com/remit/sign-up" target="_blank">
                    Sign Up
                  </a>
                </li>
                <li className="nav-item">
                  <div className="btn_block">
                    <a className="nav-link dark_btn" href="https://aremkopay.com/remit/app-login.html" target="_blank">
                      Login
                    </a>
                    <div className="btn_bottom" />
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          {/* navigation end */}
        </div>
        {/* container end */}
      </header>
      {showCookiesConsent && (
        <div className="wrapper">
        <div className="d-flex align-items-center justify-content-baseline gap-2">
        <img src="../images/cookies.png" alt="cookies consent" />
        <h5 className="mt-2 ml-2">Cookies Consent</h5>
        </div>
          <div className="content">
        
            <p>
              We use cookies to improve your browsing experience and for marketing purposes.
            </p>
            <div >
              <button className="thm-btn" onClick={acceptCookies}>
                <span className='txt'>I understand</span>
              </button>
              <NavLink to="/privacy-policy" className="item ml-3" style={{textDecoration: 'underline'}}>
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
