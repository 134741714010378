import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer
        className="white_text aos-init"
        data-aos="fade-in"
        data-aos-duration={1500}
        style={{ background: "#000" }}
      >
        <div className="footer_overlay" bis_skin_checked={1}>
          <img src="images/overlay-bg3-reverse.svg" alt="img" />
        </div>
        <div className="container" bis_skin_checked={1}>
          <div className="row" bis_skin_checked={1}>
            <div className="col-md-4" bis_skin_checked={1}>
              <div className="logo_side" bis_skin_checked={1}>
                <div className="logo pb-0 mb-0" bis_skin_checked={1}>
                  <NavLink to="/">
                    <img
                      src="images/logo.svg"
                      style={{ width: "200px" }}
                      alt="Logo"
                    />
                  </NavLink>
                </div>
                <p>
                Aremko Payments Limited is registered with FCA in the UK as Financial Institution with reference number 933662
                Companies House registration number 12601671.
                </p>
              </div>
            </div>
            <div className="col-md-4" bis_skin_checked={1}>
              <div className="news_letter" bis_skin_checked={1}>
                <h3>Quick Links</h3>
                <ul className="links" style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <NavLink className="text-white" to="/about">
                      About us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-white" to="/help">
                      Help
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-white" to="/">
                      Blogs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-white" to="/contact-us">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4" bis_skin_checked={1}>
              <div className="news_letter" bis_skin_checked={1}>
                <h3>Address</h3>
                <p>167-169 Great Portland Street 5th Floor, London, W1W 5PF</p>
              </div>
              <ul
                className="contact_info"
                style={{ listStyle: "none", padding: 0 }}
              >
                <li>
                  <a href="mailto:info@aremkopay.com"> info@aremkopay.com</a>
                </li>
                <li>
                  <a href="tel:7456793426">+44 7456793426</a>
                </li>
              </ul>
              <ul
                className="social_media"
                style={{ listStyle: "none", padding: 0 }}
              >
                <li>
                  <NavLink to="https://www.facebook.com/aremkopay/" target="_blank">
                  <i class="fa-brands fa-facebook-f"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://x.com/i/flow/login?redirect_after_login=%2FAremkopay" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://www.instagram.com/Aremkopay/" target="_blank">
                  <i class="fa-brands fa-instagram"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://www.tiktok.com/@aremkopay" target="_blank">
                    <i class="fa-brands fa-tiktok"></i>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_bottom" bis_skin_checked={1}>
          <div className="container" bis_skin_checked={1}>
            <div className="ft_inner" bis_skin_checked={1}>
              <div className="copy_text" bis_skin_checked={1}>
                <p>© Copyrights 2024. All rights reserved.</p>
              </div>
              <ul className="links" style={{ listStyle: "none", padding: 0 }}>
                <li>
                  <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/terms-and-conditions">
                    Terms and conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/complaint-policy">Complaint Policy</NavLink>
                </li>
              </ul>
              <div className="design_by" bis_skin_checked={1}>
                <p>
                  Powered by{" "}
                  <a
                    href="https://www.calyx-solutions.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Calyx Solutions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
