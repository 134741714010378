import Aos from "aos";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert';
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneno: '',
    subject: '',
    comment: '',
    reCaptcha: ''
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!formData.phoneno.trim()) {
      errors.phoneno = 'Phone number is required';
    } else if (!/^\d{10,15}$/.test(formData.phoneno)) {
      errors.phoneno = 'Phone number is invalid';
    }
    if (!formData.subject.trim()) {
      errors.subject = 'Subject is required';
    }
    if (!formData.comment.trim()) {
      errors.comment = 'Comment is required';
    }
    if (!formData.reCaptcha) {
      errors.reCaptcha = 'reCAPTCHA is required';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRecaptchaChange = (value) => {
    setFormData({
      ...formData,
      reCaptcha: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      try {
        const response = await axios.post('https://aremkopay.com/prod/api/contactus/savecontactdetails', formData);
        Swal({
          icon: 'success',
          title: 'Success',
          text: response.data,
          showConfirmButton: false,
          showCloseButton: true
        });
        setFormData({
          name: '',
          email: '',
          phoneno: '',
          subject: '',
          comment: '',
          reCaptcha: ''
        });
        
      } catch (error) {
        Swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data || 'An error occurred',
          showConfirmButton: false,
          showCloseButton: true
        });
      }
    }
  };
  
  useEffect(() => {
    Aos.init({
      duration: 2000, // duration of the animations in ms
    });
  }, []);

  return (
    <div>
      <Helmet>
  <title>AremkoPay - Contact Us</title>
  <meta name="description" content="Contact AremkoPay for support, inquiries, or feedback. Find our contact information and get in touch with our customer service team." />
  <meta name="keywords" content="AremkoPay contact, customer support, contact information, AremkoPay inquiries, customer service" />
  <meta property="og:title" content="AremkoPay - Contact Us" />
  <meta property="og:description" content="Contact AremkoPay for support, inquiries, or feedback. Find our contact information and get in touch with our customer service team." />
  <meta property="og:url" content="https://www.aremkopay.com/contact-us" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/contact-us" />
</Helmet>

      <div className="page_wrapper">
        {/* Contact Us Section Start */}
        <section className="contact_section">
          <div className="container">
            <div
              className="section_title aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span className="title_badge">Contact us</span>
              <h2>
                Any query ? <span>let's talk</span>
              </h2>
              <p>
              Reach out to our customer service team via phone for immediate assistance. Our friendly representatives are available to help you <br /> from Monday to Friday, 9 AM to 6 PM GMT.
              </p>
            </div>
            <ul className="contact_listing" style={{ listStyleType: "none" }}>
              <li
                data-aos="fade-up"
                data-aos-duration={1500}
                className="aos-init"
              >
                <span className="icon">
                  <img src="images/mail_icon.png" alt="image" />
                </span>
                <span className="lable">Email us</span>
                <a href="mailto:info@aremkopay.com">info@aremkopay.com</a>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-delay={150}
                className="aos-init"
              >
                <span className="icon">
                  <img src="images/phone_icon.png" alt="image" />
                </span>
                <span className="lable">Call us</span>
                <a href="tel:7456793426">+44 7456793426</a>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-delay={300}
                className="aos-init"
              >
                <span className="icon">
                  <img src="images/location_icon.png" alt="image" />
                </span>
                <span className="lable">Our location</span>
                <a target="_blank" href="https://www.google.com/maps">
                167-169 Great Portland Street 5th Floor <br /> , London, W1W 5PF
                </a>
              </li>
            </ul>
          </div>
        </section>
        {/* Contact Us Section End */}
        {/* Contact Form Section Start */}
        <section
      className="contact_form row_am aos-init"
      data-aos="fade-in"
      data-aos-duration={1500}
    >
      <div className="contact_inner">
        <div className="container">
          <div
            className="section_title aos-init"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-delay={100}
          >
            <span className="title_badge">Message us</span>
            <h2>Drop a message us</h2>
            <p>Fill up form below, our team will get back soon</p>
          </div>
          <form
            data-aos="fade-up"
            data-aos-duration={1500}
            className="aos-init"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errors.name ? 'error-input' : ''}`}
                    placeholder="Name *"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control ${errors.email ? 'error-input' : ''}`}
                    placeholder="Email *"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errors.phoneno ? 'error-input' : ''}`}
                    placeholder="Phone *"
                    name="phoneno"
                    value={formData.phoneno}
                    onChange={handleChange}
                    
                  />
                  {errors.phoneno && <span className="error">{errors.phoneno}</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errors.subject ? 'error-input' : ''}`}
                    placeholder="Subject *"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    
                  />
                  {errors.subject && <span className="error">{errors.subject}</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <textarea
                    className={`form-control ${errors.comment ? 'error-input' : ''}`}
                    placeholder="Comments *"
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                    
                  />
                  {errors.comment && <span className="error">{errors.comment}</span>}
                </div>
              </div>
              <div className="col-md-12">
                <ReCAPTCHA
                  sitekey="6LdZ5BoqAAAAAPeZ8V7T3CmIzXLyHIbBjGtFkFhu"
                  onChange={handleRecaptchaChange}
                />
                {errors.reCaptcha && <span className="error">{errors.reCaptcha}</span>}
              </div>
              <div className="col-md-8 " style={{visibility: 'hidden'}} >
                <div className="coustome_checkbox">
                  <label htmlFor="term_checkbox">
                    <input type="checkbox" id="term_checkbox"  />
                    <span className="checkmark" />I agree to receive emails,
                    newsletters and promotional messages
                  </label>
                </div>
              </div>
              <div className="col-md-4 text-right">
                <div className="btn_block">
                  <button className="btn puprple_btn ml-0" type="submit">
                    Submit
                  </button>
                  <div className="btn_bottom" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      
    </section>
        {/* Contact Form Section End */}
        {/* Google Map Start */}
        <div
          className="map_block row_am aos-init"
          data-aos="fade-in"
          data-aos-duration={1500}
        >
          <div className="container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.335418160624!2d-114.06571602470386!3d51.04689414424186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5371653290888c17%3A0x49a76d2331b6bb16!2s655%20Centre%20St%20S%2C%20Calgary%2C%20AB%20T2E%202P6%2C%20Canada!5e0!3m2!1sen!2sin!4v1719404820291!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        {/* Google Map End */}
        {/* Footer-Section start */}

        {/* Footer-Section end */}
        {/* go top button */}
      </div>
     
    </div>
  );
};

export default ContactUs;
