
import React, { useEffect } from "react";
import Aos from "aos";
import { NavLink } from "react-router-dom";
import "./Steps.css";
import { Helmet } from "react-helmet";
const Help = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000, // duration of the animations in ms
    });
  }, []);
  return (
    <div>
      <Helmet>
  <title>AremkoPay - Help</title>
  <meta name="description" content="Find answers to your questions and get support for your money transfer needs with AremkoPay's comprehensive help section." />
  <meta name="keywords" content="AremkoPay help, money transfer support, customer service, money transfer FAQs, AremkoPay support" />
  <meta property="og:title" content="AremkoPay - Help" />
  <meta property="og:description" content="Find answers to your questions and get support for your money transfer needs with AremkoPay's comprehensive help section." />
  <meta property="og:url" content="https://www.aremkopay.com/help" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://www.aremkopay.com/help" />
</Helmet>

      <div className="page_wrapper container">
        {/* FAQ Tab Section Start */}
        <section className="about_us_section"     data-aos="fade-up"
                data-aos-duration={3000}>
          <div
            className="container aos-init aos-animate"
           
          >
            <div
              className="section_title aos-init aos-animate"
              bis_skin_checked={1}
            >
              <span className="title_badge">Help</span>
              <h2>
                Help
                <br /> Welcome to the <span> Aremko Pay Help Center!</span>{" "}
              </h2>
              <p>
              We're here to ensure your experience with Aremko Pay is seamless and stress-free. Whether you're sending money to <br /> UK,  Canada, Nigeria  our dedicated support team is ready to assist <br /> you every step of the way.
              </p>
            </div>
          </div>
        </section>

        <section className="row_am faq_section" >
          <div className="auto-container mt-5" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1} >
              <div
                className="col-12 col-md-6 col-lg-4 mt-2"
                data-aos="fade-up"
                data-aos-duration={3000}
              >
                {/* Content */}
                <div
                  className="service_single_content text-center mb-100 fadeInUp"
                  data-wow-delay="0.3s"
                  bis_skin_checked={1}
                >
                  {/* Icon */}
                  <NavLink to="/onboarding" bis_skin_checked={1}>
                    <div className="service_icon" bis_skin_checked={1}>
                      <img src="assets/images/photo/kyc.png" alt="KYC Upload" />
                    </div>
                    <h6 className="bold">KYC Upload</h6>
                  </NavLink>
                  {/* Button trigger modal */}
                  {/*<button type="button" class="btn btn-primary video-btn2" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/qceO0YPfcVo" data-bs-target="#myModal">
                          <i class="fa fa-play"></i>&nbsp;&nbsp;Watch Explainer Video
                      </button>*/}
                  {/*<p>Lorem ipsum dolor sit amet, adipiscing elit. Nulla neque quam, maxi ut ac cu msan ut, posuere sit Lorem ipsum qu.</p>*/}
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-4 mt-2"
                data-aos="fade-up"
                data-aos-duration={3000}
              >
                {/* Content */}
                <div
                  className="service_single_content text-center mb-100 fadeInUp"
                  data-wow-delay="0.2s"
                  bis_skin_checked={1}
                >
                  {/* Icon */}
                  <NavLink to="/onboarding-faq" bis_skin_checked={1}>
                    <div className="service_icon" bis_skin_checked={1}>
                      <img
                        src="assets/images/photo/onbordingfaq.png"
                        alt="Onboarding FAQs"
                      />
                    </div>
                    <h6 className="bold">Onboarding FAQs</h6>
                  </NavLink>
                  {/*<p>Lorem ipsum dolor sit amet, adipiscing elit. Nulla neque quam, maxi ut ac cu msan ut, posuere sit Lorem ipsum qu.</p>*/}
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-4 mt-2"
                data-aos="fade-up"
                data-aos-duration={3000}
              >
                {/* Content */}
                <div
                  className="service_single_content text-center mb-100 fadeInUp"
                  data-wow-delay="0.4s"
                  bis_skin_checked={1}
                >
                  {/* Icon */}
                  <NavLink to="/data-safety" bis_skin_checked={1}>
                    <div className="service_icon" bis_skin_checked={1}>
                      <img
                        src="assets/images/photo/data-safety.png"
                        alt="Data Safety"
                      />
                    </div>
                    <h6 className="bold">Data Safety</h6>
                  </NavLink>
                  {/*<p>Lorem ipsum dolor sit amet, adipiscing elit. Nulla neque quam, maxi ut ac cu msan ut, posuere sit Lorem ipsum qu.</p>*/}
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-4 mt-2"
                bis_skin_checked={1}
                
              >
                {/* Content */}
                <div
                  className="service_single_content text-center mb-100 fadeInUp"
                  data-wow-delay="0.4s"
                  bis_skin_checked={1}
                >
                  {/* Icon */}
                  <NavLink to="/faq" bis_skin_checked={1}>
                    <div className="service_icon" bis_skin_checked={1}>
                      <img
                        src="assets/images/photo/faq.png"
                        alt="General FAQs"
                      />
                    </div>
                    <h6 className="bold">General FAQs</h6>
                  </NavLink>
                  {/*<p>Lorem ipsum dolor sit amet, adipiscing elit. Nulla neque quam, maxi ut ac cu msan ut, posuere sit Lorem ipsum qu.</p>*/}
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-4 mt-2"
                data-aos="fade-in"
                data-aos-duration={1500}
              >
                {/* Content */}
                <div
                  className="service_single_content text-center mb-100 fadeInUp"
                  data-wow-delay="0.4s"
                  bis_skin_checked={1}
                >
                  {/* Icon */}
                  <NavLink to="/contact-us" bis_skin_checked={1}>
                    <div className="service_icon" bis_skin_checked={1}>
                      <img
                        src="assets/images/photo/contactinfo.png"
                        alt="Contact Info"
                      />
                    </div>
                    <h6 className="bold">Contact Info</h6>
                  </NavLink>
                  {/*<p>Lorem ipsum dolor sit amet, adipiscing elit. Nulla neque quam, maxi ut ac cu msan ut, posuere sit Lorem ipsum qu.</p>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQ Tab Section End */}
      </div>
    </div>
  );
};

export default Help;
